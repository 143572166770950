import { template as template_491d673674274aafacecc95e00d4f847 } from "@ember/template-compiler";
const WelcomeHeader = template_491d673674274aafacecc95e00d4f847(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
