import { template as template_eb4e55722ebb4a5d900e1a13a7b73d9d } from "@ember/template-compiler";
const FKLabel = template_eb4e55722ebb4a5d900e1a13a7b73d9d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
